import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { Formulation } from './formulation';
import * as _ from 'lodash';
import { getFormErrorSentence, ShouldBeAllDifferent, ShouldBeHaveAtLeastTwoFieldsFilled } from 'src/app/shared/helpers/formattedInput';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { LocalService } from 'src/app/api/local-storage.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TabsPublicName } from 'src/app/shared/helpers/tabsPublicName';
import { Tabs } from 'src/app/shared/helpers/tabs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class KnowledgeSearchService {
  Formulation = Formulation;
  selectedFormulation = undefined;
  tabsDataSource: Array<Tabs> = [
    { value: TabsName.Query, label: TabsPublicName.Query }
  ];
  activeTab = this.tabsDataSource[0];

  results = false;
  editMode = false;
  PagesName = PagesName;
  isActivelySearching = false;
  backendError = undefined;
  keywordsForm = new UntypedFormGroup(
    {
      keywords0: new UntypedFormControl(undefined),
      keywords1: new UntypedFormControl(undefined),
      keywords2: new UntypedFormControl(undefined),
      keywords3: new UntypedFormControl(undefined),
      keywords4: new UntypedFormControl(undefined),
      keywords5: new UntypedFormControl(undefined),
    },
    [ShouldBeHaveAtLeastTwoFieldsFilled(), ShouldBeAllDifferent()]
  );
  articleForm = new UntypedFormGroup({
    pmcId: new UntypedFormControl(undefined),
  });
  debugMode = false;
  retrievedResult = new BehaviorSubject<SearchResultDTO | null>(null);

  minDateFilterValue: number = null;
  maxDateFilterValue: number = null;
  selectedBucketFilter: string = '';
  selectedCategoryFilters: { [id: string]: boolean } = {};
  selectedJournalFilters: { [name: string]: boolean } = {};
  sortedBy: string = 'publication_date,desc';

  currentProject: any;

  colorMapping: { [id: string]: string } = {};

  protected basePath = 'XXXX';
  constructor(private readonly http: HttpClient, public router: Router, private localService: LocalService) {}
  getFormulationFullName = (formulation: Formulation) => {
    let name;
    switch (formulation) {
      case Formulation.Keywords:
        name = PagesPublicName.KnowledgeSearchSimpleKeyword;
        break;
      case Formulation.Therapy:
        name = PagesPublicName.KnowledgeSearchPicoTherapy;
        break;
      case Formulation.Prevention:
        name = PagesPublicName.KnowledgeSearchPicoPrevention;
        break;
      case Formulation.Diagnosis:
        name = PagesPublicName.KnowledgeSearchPicoDiagnosis;
        break;
      case Formulation.Prognosis:
        name = PagesPublicName.KnowledgeSearchPicoPrognosis;
        break;
      case Formulation.Etiology:
        name = PagesPublicName.KnowledgeSearchPicoEtiology;
        break;
      case Formulation.Article:
        name = PagesPublicName.KnowledgeSearchSingleArticle;
        break;
      default:
        name = '';
        break;
    }
    return name;
  };

  clearCategoryFilters() {
    this.selectedCategoryFilters = {};
  }

  clearJournalFilters() {
    this.selectedJournalFilters = {};
  }

  clearDateFilters() {
    this.minDateFilterValue = null;
    this.maxDateFilterValue = null;
  }

  convertTimestampToYear(timestamp: number, { yearEnd }: { yearEnd: boolean } = { yearEnd: false }) {
    return new Date(`${timestamp}-${yearEnd ? '12-31' : '01-01'}`).getTime() / 1000;
  }

  postkeywords(array) {
    let basePath;
    let userIsCA = this.localService.getFromLocalStorage('user', 'account_country') === 'CA';
    let userCanAccessCA = !_.isEmpty(_.get(this.localService.getFromLocalStorage('user', 'external_user_ids'), 'CA'));
    let params = new HttpParams().appendAll({
      keyword: array,
      ...(this.selectedBucketFilter && { filter_bucket: this.selectedBucketFilter.split(',') }),
      filter_category_and: Object.entries(this.selectedCategoryFilters)
        .filter(([_, selected]) => selected)
        .map(([id]) => id),
      filter_journal: Object.entries(this.selectedJournalFilters)
        .filter(([_, selected]) => selected)
        .map(([name]) => name),
    });
    let headers = new HttpHeaders({
      'sort-by': this.sortedBy,
      ...(this.minDateFilterValue &&
        this.maxDateFilterValue && {
          'date-range': `${this.convertTimestampToYear(this.minDateFilterValue)},${this.convertTimestampToYear(this.maxDateFilterValue, {
            yearEnd: true,
          })}`,
        }),
    });
    if (environment.apiBasePath.includes('frontdoor') && (userIsCA || userCanAccessCA)) {
      let country = 'CA';
      headers = headers.set('country', country);
      basePath = 'https://mimsomicfrontdoor.azurefd.net';
    } else {
      basePath = this.basePath;
    }
    return this.http.get<SearchResultDTO>(`${basePath}/mas/fs/projects/litt-research`, { headers, params });
  }

  getQueryParams() {
    return this.localService.getFromLocalStorage('queryParams');
  }
  setQueryParams(value) {
    this.localService.setLocalStorage('queryParams', value);
  }
  saveQueryParams(form: UntypedFormGroup) {
    let queryParams = this.updateQueryParams(form, this.getQueryParams());

    this.setQueryParams(queryParams);
  }
  search() {
    let queryParams = this.getQueryParams();
    let array = [
      _.get(queryParams, 'k0', '').trim(),
      _.get(queryParams, 'k1', '').trim(),
      _.get(queryParams, 'k2', '').trim(),
      _.get(queryParams, 'k3', '').trim(),
      _.get(queryParams, 'k4', '').trim(),
    ];
    array = _.without(Object.values(array), '');
    if (this.selectedFormulation === Formulation.Therapy) {
      array.push('intervention');
    }
    if (this.selectedFormulation === Formulation.Article) {
      array.push('tripleArticle');
    }

    this.retrievedResult.next(null);
    this.isActivelySearching = true;
    this.postkeywords(array).subscribe(
      (res) => {
        this.isActivelySearching = false;
        if (typeof res === null) {
          this.backendError = 'Internal Error';
          this.retrievedResult.next(null);
          return;
        }
        if (this.selectedFormulation === Formulation.Therapy) {
          res = _.filter(res, (o) => {
            return this.getFocus(o).includes('intervention');
          });
        }
        this.retrievedResult.next(res);
        this.minDateFilterValue = new Date(res.date_range[0] * 1000).getFullYear();
        this.maxDateFilterValue = new Date(res.date_range[1] * 1000).getFullYear();
        this.sortedBy = res.sort_by;
      },
      (error) => {
        this.isActivelySearching = false;
        this.backendError = _.get(error, 'message', 'Internal Error');
      }
    );
  }

  getFocus(cluster) {
    return _.toLower(_.join(_.get(this.getFirstClusterTriple(cluster), 'gas_focus'), ', '));
  }
  getArticlesArray = (cluster) => {
    return Object.values(cluster);
  };
  getFirstClusterArticle = (cluster) => {
    return _.first(this.getArticlesArray(cluster));
  };
  getFirstClusterTriple = (cluster) => {
    return _.first(Object.values(this.getFirstClusterArticle(cluster)));
  };
  updateQueryParams(form, queryParams) {
    let updatedQueryParam = _.clone(queryParams);
    const valueArray = Object.values(form.controls).map((control) => {
      return _.get(control, 'value');
    });
    _.set(updatedQueryParam, 'k0', _.nth(valueArray, 0) || '');
    _.set(updatedQueryParam, 'k1', _.nth(valueArray, 1) || '');
    _.set(updatedQueryParam, 'k2', _.nth(valueArray, 2) || '');
    _.set(updatedQueryParam, 'k3', _.nth(valueArray, 3) || '');
    _.set(updatedQueryParam, 'k4', _.nth(valueArray, 4) || '');
    _.set(updatedQueryParam, 'k5', _.nth(valueArray, 5) || '');
    return updatedQueryParam;
  }

  resetForm(form: UntypedFormGroup) {
    form.reset();
  }
  getFormValidity(form: UntypedFormGroup) {
    return form.status === 'VALID';
  }
  getFormError(form: UntypedFormGroup) {
    if (this.getFormValidity(form)) {
      return '';
    }
    if (_.get(form.errors, 'ShouldBeHaveAtLeastTwoFieldsFilled')) {
      return getFormErrorSentence('ShouldBeHaveAtLeastTwoFieldsFilled');
    }
    if (_.get(form.errors, 'ShouldBeAllDifferent')) {
      return getFormErrorSentence('ShouldBeAllDifferent');
    }
    return 'Please verify your form';
  }

  getQueryParamAsString = (queryParams) => {
    let newArray = _.omit(queryParams, 'id');
    newArray = _.omit(newArray, 'country');
    newArray = _.without(Object.values(newArray), '');
    newArray = _.without(newArray, null);
    return _.join(newArray, ', ');
  };
  updateActiveTab = (tab) => {
    this.activeTab = _.find(this.tabsDataSource, (o) => {
      return o.value === tab;
    });
  };
  navigateToTab = (tab: TabsName) => {
    if (this.editMode) {
      if (tab && this.selectedFormulation && this.results) {
        this.router.navigate([PagesName.KnowledgeSearch, tab, this.selectedFormulation, PagesName.Results, PagesName.Edit]);
        return;
      }
      if (tab && this.selectedFormulation) {
        this.router.navigate([PagesName.KnowledgeSearch, tab, this.selectedFormulation, PagesName.Edit]);
        return;
      }
      this.router.navigate([PagesName.KnowledgeSearch, tab, PagesName.Edit]);
    } else {
      if (tab && this.selectedFormulation && this.results) {
        this.router.navigate([PagesName.KnowledgeSearch, tab, this.selectedFormulation, PagesName.Results]);
        return;
      }
      if (tab && this.selectedFormulation) {
        this.router.navigate([PagesName.KnowledgeSearch, tab, this.selectedFormulation]);
        return;
      }
      this.router.navigate([PagesName.KnowledgeSearch, tab]);
    }
  };
  navigateToSelectedFormulation = (formulation: Formulation, queryParams) => {
    if (this.editMode) {
      this.setQueryParams(_.pick(queryParams, 'id'));
      this.resetForm(this.articleForm);
      this.resetForm(this.keywordsForm);
      this.resetFilters();
      this.router.navigate([PagesName.KnowledgeSearch, TabsName.Query, formulation, PagesName.Edit]);
    } else {
      this.setQueryParams({});
      this.resetFilters();
      this.router.navigate([PagesName.KnowledgeSearch, TabsName.Query, formulation]);
    }
  };
  navigateBackToSelectFormulation = () => {
    this.router.navigate([PagesName.KnowledgeSearch, TabsName.Query, PagesName.Edit]);
  };
  navigateToResults = (formulation: Formulation) => {
    if (this.editMode) {
      this.router.navigate([PagesName.KnowledgeSearch, TabsName.Query, formulation, PagesName.Results, PagesName.Edit]);
    } else {
      this.router.navigate([PagesName.KnowledgeSearch, TabsName.Query, formulation, PagesName.Results]);
    }
  };
  navigateBackToSearchForm = (formulation: Formulation) => {
    this.router.navigate([PagesName.KnowledgeSearch, TabsName.Query, formulation, PagesName.Edit]);
  };

  toggleDebugMode = () => {
    this.debugMode = !this.debugMode;
  };

  fetchArticle = (id: ArticleModel['pmcid']) => {
    return this.http
      .get<{ [key: string]: ArticleModel }>(`${this.basePath}/mas/fs/projects/litt-research-article`, { params: { id } })
      .pipe(map((res) => res[id]));
  };

  resetFilters() {
    this.sortedBy = 'publication_date,desc';
    this.selectedCategoryFilters = {};
    this.minDateFilterValue = null;
    this.maxDateFilterValue = null;
    this.selectedBucketFilter = '';
    this.selectedJournalFilters = {};
  }

  applyFilters(filters: AppliedFilters) {
    this.sortedBy = filters.sort || 'publication_date,desc';
    this.selectedCategoryFilters = filters.selectedCategoryFilters || {};
    this.minDateFilterValue = filters.minDateFilterValue || null;
    this.maxDateFilterValue = filters.maxDateFilterValue || null;
    this.selectedBucketFilter = filters.selectedBucketFilter || '';
    this.selectedJournalFilters = filters.selectedJournalFilters || {};
  }

  getActiveFilters(): AppliedFilters {
    return {
      sort: this.sortedBy,
      selectedCategoryFilters: this.selectedCategoryFilters,
      selectedBucketFilter: this.selectedBucketFilter,
      selectedJournalFilters: this.selectedJournalFilters,
      minDateFilterValue: this.minDateFilterValue,
      maxDateFilterValue: this.maxDateFilterValue
    }
  }

  hasActiveFilters() {
    return (
      (this.selectedCategoryFilters && Object.keys(this.selectedCategoryFilters).length > 0) ||
      !!this.minDateFilterValue ||
      !!this.maxDateFilterValue ||
      !!this.selectedBucketFilter ||
      (this.selectedJournalFilters && Object.keys(this.selectedJournalFilters).length > 0)
    );
  }

  // Returns the color of a category. Once a color is found, we cache it for future usage
  colorForCategory = (id: CategoryModel['id']) => {
    let color = '';

    if (id) {
      if (this.colorMapping[id.toUpperCase()]) {
        return this.colorMapping[id.toUpperCase()];
      }

      if (ROOT_CATEGORIES[id.toUpperCase()]) {
        color = ROOT_CATEGORIES[id.toUpperCase()][0];
      } else {
        const parentColor = this.colorForCategory(this.retrievedResult.value.categories.categories[id.toLowerCase()]?.parent);
        if (parentColor) {
          color = parentColor;
        }
      }
      this.colorMapping[id.toUpperCase()] = color;
    }

    return color;
  };

  nameForCategory = (id: CategoryModel['id']) => {
    return this.retrievedResult.value.categories.categories[id.toLowerCase()]?.name || ROOT_CATEGORIES[id.toUpperCase()]?.[1] || '';
  };

  handleSortChange() {
    this.search();
  }

  setCurrentProject(project) {
    this.currentProject = project;
    if (project) {
      const filters = JSON.parse(_.get(project, 'metadata.filters', '{}'));
      this.applyFilters(filters);
    }
  }
}

const ROOT_CATEGORIES: { [key: string]: [string, string] } = {
  T170: ['#82E0A2', 'Intellectual Product'],
  T033: ['#1695A3', 'Finding'],
  T078: ['#D1DBBD', 'Idea or Concept'],
  T032: ['#47D9BF', 'Organism Attribute'],
  T090: ['#5ADED4', 'Occupation or Discipline'],
  T102: ['#D0E64B', 'Group Attribute'],
  T096: ['#9AC836', 'Group'],
  T092: ['#91AA9D', 'Organization'],
  T171: ['#02A676', 'Language'],
  T070: ['#FFD5AE', 'Natural Phenomenon or Process'],
  T037: ['#FA7B12', 'Injury or Poisoning'],
  T068: ['#FFBB20', 'Human-caused Phenomenon or Process'],
  T056: ['#CFEEFF', 'Daily or Recreational Activity'],
  T057: ['#4192D9', 'Occupational Activity'],
  T053: ['#0074d9', 'Behavior'],
  T066: ['#1A8BB3', 'Machine Activity'],
  T073: ['#FFCFFA', 'Manufactured Object'],
  T001: ['#C07DB0', 'Organism'],
  T167: ['#F2DDEE', 'Substance'],
  T017: ['#C78FD1', 'Anatomical Structure'],
};

export interface ArticleModel {
  pmcid: string;
  title: string;
  url: string;
  journal: string;
  type: string;
  publication_date: number;
  authors: string;
  abstract: string;
}

export interface SemanticModel {
  sentence: string;
  article: ArticleModel['pmcid'];
  search_focus: string[];
  linked_entities: [string, string][];
  relevancy: number;
};

export interface SearchResultDTO {
  buckets: BucketModel[];
  date_range: [number, number];
  categories: SearchResultCategoriesDTO;
  semantics: SemanticModel[];
  sort_by: string;
  top_journals: [string, number][]
}

export interface BucketModel {
  bucket: string[];
  triple_count: number;
}

export interface CategoryModel {
  id: string;
  name: string;
  count: number;
  color: string;
  children: CategoryModel[];
}

export interface SearchResultCategoriesDTO {
  roots: string[];
  categories: {
    [key: string]: {
      name: string;
      parent: string;
      triple_count: number;
      children: string[];
    };
  };
}

export interface AppliedFilters {
  sort: string;
  selectedCategoryFilters: { [key: string]: boolean };
  selectedBucketFilter: string;
  selectedJournalFilters: { [key: string]: boolean };
  minDateFilterValue: number | null;
  maxDateFilterValue: number | null;
}
